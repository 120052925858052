export const environment = {
  production: true,
  url: 'https://api.taqe.com.br/v2',
  branch_key: 'key_live_gdwaWEQt6nVzI7hXGFPy5amhuuk6R2tV',
  link_branch: 'https://taqe.app.link/',
  url_pwa: 'https://app.taqe.com.br',
  type: 'taqe',
  default_SEO_img: 'https://cdn.branch.io/branch-assets/1535740907442-og_image.png',
  url_landing: 'https://vagas.taqe.com.br',
  whiteLabel: {
    espro: {
      url_pwa: 'https://espro-app.taqe.com.br',
    },
    'ciee-rj': {
      url_pwa: 'https://cieerj-app.taqe.com.br',
    }
  }
};
